  .home-right{
    height: 100%;
  }

  .home-right-main{
    height: 100%;
  }

  .home-right-main-img{
      height: 65vh;
      margin: auto;    
      display: block;
  }

  .home-right-footer{
     width: 100%;
  }
