.social-icons{
    text-align: center;
  }
 
  .fb{
    margin: 2%;
    color: white;
  }

  .fb:hover{
    color: #386ddd;
  }

  .git{
    margin: 2%;
    color: white;
  }

  .git:hover{
    color: #16fa01;
  }

  .twitter{
    margin: 2%;
    color: white;
  }

  .twitter:hover{
    color: #00acee;
  }

  .insta{
    margin: 2%;
    color: white;
  }

  .insta:hover{
    color: #c1558b;
  }

  .linkedin{
    margin: 2%;
    color: white;
  }
  
  .linkedin:hover{
    color: #0e76a8;
  }