@import url('https://fonts.googleapis.com/css?family=Indie+Flower|Lobster+Two|Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Roboto+Mono|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap');

.clc_main{
    color: white;
    height: 100%;
    width: 100%;
    padding: 2%;
    padding-top: 30%;
}

.clc_container{
  padding: 2%;
}

.clc_header{
  text-align: center;
  font-family: 'Sulphur Point', sans-serif;
}

.clc_sub_header{
  font-family: 'Indie Flower', cursive;
}

.clc_paragraph{
  font-family: 'Roboto Mono', monospace;
}

.AboutBtnContainer{
  width: 100%;
}

.left{
  width: 50%;
  float: left;
  text-align: center;
  font-family: 'Roboto Mono', monospace;
  font-weight: 900;
  color: aliceblue;
}

.right{
  width: 50%;
  float: right;
  text-align: center;
  font-family: 'Roboto Mono', monospace;
  font-weight: 900;
}

.right_icon{
  font-size: 100px;
}

.right_icon:hover{
  color: #F1502F;
  cursor: pointer;
}

.left_icon{
font-size: 100px;
}

.left_icon:hover{
  color: #ffbb48;
  cursor: pointer;
}

.about_modal{
  background-color: rgb(9, 9, 9);
}

.custom-modal{
  width: 100VW;
  height: 100VH;
}

.modal-exit-btn:hover{
  background-color:#666666;
  color: seashell;
  cursor: pointer;
}

.modalHeader{
  background-color: #090909;
  color: aliceblue !important;
}

.close{
  color: aliceblue;
}